@import './mixins.scss';
@import './overrides.scss';

.landing-page__illo {
  max-width: 100%;
}

.landing-page__banner {
  padding-top: $spacing-05;
  padding-bottom: $spacing-07 * 4;
  @include landing-page-background;
}

.landing-page__heading {
  @include carbon--type-style('productive-heading-05');
}

.landing-page__r2 {
  margin-top: rem(-40px);
}

.landing-page__tab-content {
  padding-top: $layout-05;
  padding-bottom: $layout-05;
}

.landing-page__subheading {
  margin-top: 20px;
  @include carbon--type-style('productive-heading-03');
}

.landing-page__p {
  @include carbon--type-style('body-long-02');

  margin-top: $spacing-05;
  margin-bottom: $spacing-05;

  @include carbon--breakpoint-between((320px + 1), md) {
    max-width: 75%;
  }
}

.landing-page__r3 {
  padding-top: $spacing-09;
  padding-bottom: $spacing-09;
  @include landing-page-background;
}

.landing-page__label {
  @include carbon--type-style('heading-01');
}

/*
.login-page-button {
  border-top: solid 1px #dcdcdc;
  //padding-top: 10px;
  //padding-bottom: 10px;
  background-color: white;
  position: sticky;

  bottom: 0;
  min-width: 1600px;
  width: 100%;
  height: 100px;
}

.start_button {
  position: fixed;
  right: 25%;
  bottom: 5%;
}
*/

.start_button {
  margin-top: 1rem;
  float: right;
  right: 25%;
}

.login-page-button {
  border-top: solid 1px #dcdcdc;
  margin-top: 100px;
  //padding-bottom: 10px;
  background-color: white;
  position: sticky;

  bottom: 0;
  min-width: 1400px;
  width: 100%;
  height: 5rem;
}
