.feedbackRatioGroup .bx--radio-button-wrapper {
  //margin-right: 2.5rem;
  //flex-grow: 1;
  flex: auto;
}

.bx--radio-button-group.feedbackRatioGroup.bx--radio-button-group--label-right {
  width: 100%;
}

.bx--fieldset {
  margin-bottom: 0rem;
}

.textArea {
  margin-left: 10px;
}

.textArea .bx--label {
  margin-top: 20px;
}
