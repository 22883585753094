@import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';

@import './components/Info/info.scss';
@import './components/TutorialHeader/tutorial-header.scss';
@import './content/LandingPage/landing-page.scss';
@import './content/RepoPage/repo-page.scss';
@import './content/AssistantPage/assistant-page.scss';
@import './content/FeedbackPage/feedback-page.scss';

.productive-heading-01 {
  @include carbon--type-style('productive-heading-01');
}

.productive-heading-02 {
  @include carbon--type-style('productive-heading-02');
}

.productive-heading-03 {
  @include carbon--type-style('productive-heading-03');
}

.productive-heading-04 {
  @include carbon--type-style('productive-heading-04');
}

.body-short-02 {
  @include carbon--type-style('body-short-02');
}

.body-short-01 {
  @include carbon--type-style('body-short-01');
}

.code-01 {
  @include carbon--type-style('code-01');
}

.helper-text-01 {
  @include carbon--type-style('helper-text-01');
}
