.bx--side-nav {
  max-width: 32rem;
}

.bx--side-nav--ux {
  width: 23rem;
}

.bx--side-nav--expanded {
  //width: 23rem;
  //width: 353px;
}

.bx--content {
  min-height: 95vh;
  //height: 100%;
}

#completeSVG1 {
  fill: blue;
}

.bx--fieldset.some-class {
  margin-bottom: 1rem;
}

.bx--inline-notification {
  max-width: 100rem;
}

#main-content {
  background-color: #f4f4f4;
}

.regtest_modal_No {
  top: 0%;
} 

.regtest_modal_Yes {
  top: 50%;
} 

.landing-page__tab-content {
  //padding-top: 1rem;
}

.bx--side-nav a.bx--header__menu-item .bx--text-truncate-end,
a.bx--side-nav__link > .bx--side-nav__link-text {
  white-space: normal;
  padding: 10px 0 10px 0;
}

.bx--side-nav__icon:not(.bx--side-nav__submenu-chevron) {
  margin-right: 1rem;
}

.bx--list--unordered > .bx--list__item {
  font-size: 1rem;
  padding-top: 5px;
  line-height: 20px;
  //display: flex;
}

.bx--list--unordered > .bx--list__item::before {
  position: relative;
  left: 0;
  padding-right: 5px;
}

.bx--list--unordered {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  list-style: none;
  list-style-position: inside;
  text-indent: -1em;
  padding-left: 1em;
}

.bx--list--ordered:not(.bx--list--nested) > .bx--list__item::before {
  position: relative;
  left: 0;
  padding-right: 5px;
}

.bx--list--ordered:not(.bx--list--nested) > .bx--list__item {
  font-size: 1rem;
  padding-top: 5px;
}
